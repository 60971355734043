




























































































































































































































import { Component, Inject, Vue } from 'vue-property-decorator'
import { TeacherController } from '@/services/request.service'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { fileUploader } from '@/services/qiniu.service'

@Component({
  components: {
    VueCropper,
  },
})
export default class Personal extends Vue {
  private saveInfoLoading: boolean = false
  private teacherInfo: any = {}
  private mode = 'view'
  private saveLoading: boolean = false
  private personalInfo: any
  private passwordForm: any
  private visible: boolean = false

  private validateName(rule, value, callback): void {
    const form = this.personalInfo
    const name = form.getFieldValue('name')
    const enName = form.getFieldValue('enName')
    if (enName || name) {
      callback()
    } else {
      callback(this.$t('personal.nameValidateTip'))
    }
  }

  private edit(): void {
    this.mode = 'edit'
    this.$nextTick(() => {
      this.personalInfo.setFieldsValue(this.teacherInfo)
    })
  }

  private modifyPassword(): void {
    this.saveLoading = true
    const form = this.passwordForm
    form.validateFields((err, values) => {
      if (err) {
        this.saveLoading = false
        return
      } else {
        const request = {} as any
        request.oldPassWord = values.password
        request.newPassword = values.newPassword
        TeacherController.updatePassword(request)
          .then(res => {
            if (!res.data.success) {
              form.setFields({
                ['password']: {
                  errors: [{ message: this.$t('personal.passwordLimit'), field: 'password' }],
                },
              })
            } else {
              this.$message.success(this.$tc('common.saveSuccess'))
              this.visible = false
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.saveLoading = false
          })
      }
    })
  }

  private editCancel(): void {
    this.mode = 'view'
  }

  private saveStudentInfo(): void {
    this.saveInfoLoading = true
    const form = this.personalInfo
    form.validateFields((err: any, values: any) => {
      if (err) {
        this.saveInfoLoading = false
        return
      } else {
        values.teacherId = this.teacherInfo.teacherId
        values.email = values.accountNo
        TeacherController.update(values)
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
            this.mode = 'view'
            this.$store.commit('setUserName', `${values.enName} ${values.name}`.trim())
            this.getData()
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.saveInfoLoading = false
          })
      }
    })
  }

  private cancel(): void {
    this.visible = false
  }

  private modify(): void {
    this.visible = true
  }

  private beforeCreate(): void {
    this.personalInfo = this.$form.createForm(this)
    this.passwordForm = this.$form.createForm(this)
  }

  private created(): void {
    this.$nextTick(() => {
      this.getData()
    })
  }

  private getData(): void {
    TeacherController.getTeacherDetail()
      .then(res => {
        this.teacherInfo = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        //
      })
  }

  public validateToNextPassword(rule, value, callback): void {
    const form = this.passwordForm
    if (/^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/.test(value)) {
      callback()
    } else {
      callback(this.$t('personal.passwordUnLaw'))
    }
  }

  public compareToFirstPassword(rule, value, callback): void {
    const form = this.passwordForm
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(this.$t('personal.wrongPassword'))
    } else {
      callback()
    }
  }
}
