








































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import moment from 'moment'
import debounce from 'lodash/debounce'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { SenController, DropDownController } from '@/services/request.service'
import { i18n } from '@/i18n/i18n'
import { clearEmptyArray, toPage } from '@/utils/utils'
import filterGroup from '@/components/filterGroup'
import DetailModal from '@/components/DetailModal.vue'
import { createPagination } from '@/constant/constant'

@Component({
  computed: {
    ...mapState({
      operationAuths: 'operationAuths',
    }),
  },
  components: {
    FlexTooltip,
    filterGroup,
    DetailModal,
  },
})
export default class Sen extends Vue {
  private filter: any = {
    learningSpecialty: [],
    accommodationsAndInterventions: [],
    advisoryId: undefined,
    homeroomId: [],
    createTime: [],
    studentName: '',
  }
  private loading: boolean = false
  private homerooms: any = []
  private advisories: any = []
  private learningSpecialties: any = []
  private accommodationsAndInterventions: any = []
  private detailModalVis: boolean = false
  private data: any = []
  private senInfo: any = {
    title: '',
    infoList: [],
  }
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })

  private get locale(): any {
    return this.$store.state
  }

  private get columns(): Array<Object> {
    return [
      {
        dataIndex: 'students',
        title: this.$t('common.personName'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        dataIndex: 'firstTypes',
        title: this.$t('sen.learningSpecialty'),
        scopedSlots: { customRender: 'learningSpecialty' },
      },
      {
        dataIndex: 'secondTypes',
        title: this.$t('sen.accommodationsAndInterventions'),
        scopedSlots: { customRender: 'accommodationsAndInterventions' },
        ellipsis: true,
      },
      {
        dataIndex: 'classNames',
        title: this.$t('common.homeroom'),
        ellipsis: true,
        scopedSlots: { customRender: 'homeroom' },
      },
      {
        dataIndex: 'houseNames',
        title: this.$t('common.advisory'),
        ellipsis: true,
        scopedSlots: { customRender: 'advisory' },
      },
      {
        dataIndex: 'creator',
        title: this.$t('common.creator'),
        ellipsis: true,
        scopedSlots: { customRender: 'creator' },
      },
      {
        dataIndex: 'createTime',
        title: this.$t('common.createTime'),
        ellipsis: true,
        scopedSlots: { customRender: 'createTime' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        width: 120,
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  private get showChild(): boolean {
    return this.$route.name !== 'sen'
  }

  private addSen(): void {
    this.$router.push({ name: 'senEditor', query: { type: 'add' } })
  }

  private created() {
    this.getData()
    this.getDropDownInfo()
  }

  private deleteSen(id): void {
    SenController.delete(id)
      .then(res => {
        this.$message.success(this.$tc('tips.deleteSuccess'))
      })
      .catch(err => console.log(err))
      .finally(() => this.refresh())
  }

  private editSen(id): void {
    this.$router.push({ name: 'senEditor', query: { type: 'edit', id } })
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    this.loading = true
    this.data = []
    const [start, end] = this.filter.createTime
    let condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      studentName: this.filter.studentName.trim() || undefined,
      firstTypeIds: this.filter.learningSpecialty,
      secondTypeIds: this.filter.accommodationsAndInterventions,
      houseGroupId: this.filter.advisoryId || undefined,
      classId: this.filter.homeroomId[1] || undefined,
      createTimeStart: start ? moment(start).valueOf() : undefined,
      createTimeEnd: end ? moment(end).valueOf() : undefined,
    }
    SenController.getList(condition)
      .then(res => {
        this.data = res.data.items
        const { pageSize, pageCurrent, totalItem } = res.data
        this.pagination.total = totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getStudentClass(),
      DropDownController.getStudentHouseGroup(),
      DropDownController.getSenTypes('1001'),
      DropDownController.getSenTypes('1002'),
    ])
      .then(res => {
        this.homerooms = clearEmptyArray(res[0].data)
        this.advisories = res[1].data
        this.learningSpecialties = res[2].data
        this.accommodationsAndInterventions = res[3].data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.getData(pagination)
  }

  private mounted() {
    ;(this.$refs.LSelect as Vue).$el.querySelectorAll('input')[0]?.setAttribute('readonly', '')
    ;(this.$refs.ASelect as Vue).$el.querySelectorAll('input')[0]?.setAttribute('readonly', '')
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private reset(): void {
    this.filter = {
      learningSpecialty: [],
      accommodationsAndInterventions: [],
      advisoryId: undefined,
      homeroomId: [],
      createTime: [],
      studentName: '',
    }
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private viewDetail(id): void {
    SenController.detail(id).then(res => {
      const {
        firstTypes,
        secondTypeIds,
        meetingTime,
        location,
        description,
        students,
        teachers,
        resources,
        creator,
        houseNames: advisory,
        classNames: homeroom,
      } = res.data
      this.senInfo.title = this.$t('sen.senDetail')
      this.senInfo.infoList = [
        {
          key: 'learningSpecialty',
          label: this.$t('sen.learningSpecialty'),
          value: (firstTypes || []).map(item => item.name).join('、'),
        },
        {
          key: 'accommodationsAndInterventions',
          label: this.$t('sen.accommodationsAndInterventions'),
          value: (secondTypeIds || []).map(item => item.name).join('、'),
        },
        {
          key: 'description',
          label: this.$t('common.description'),
          value: description,
          visRow: 6,
        },
        {
          key: 'creator',
          label: this.$t('common.creator'),
          value: creator,
        },
        {
          key: 'homeroom',
          label: this.$t('common.homeroom'),
          value: homeroom,
        },
        {
          key: 'advisory',
          label: this.$t('common.advisory'),
          value: advisory,
        },
        {
          key: 'meetingTime',
          label: this.$t('sen.meetingTime'),
          value: Vue.filter('moment')(moment(meetingTime), 'YYYY-MM-DD HH:mm'),
        },
        {
          key: 'location',
          label: this.$t('calendar.location'),
          value: location,
        },
        {
          key: 'relativeStudents',
          label: this.$t('sen.relativeStudents'),
          value: (students || []).map(item => item.name).join('、'),
        },
        {
          key: 'relativeTeachers',
          label: this.$t('sen.relativeTeachers'),
          value: (teachers || []).map(item => `${item.enName} ${item.name}`.trim()).join('、'),
        },
        {
          key: 'attachment',
          label: this.$t('courseMaterial.attachment'),
          value: '',
          attachments: resources.map(item => {
            return {
              uid: item.resourceId,
              url: item.resourceUrl,
              name: item.resourceName,
              status: 'done',
            }
          }),
        },
      ]
      this.detailModalVis = true
    })
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (from && from.meta.refresh === true) {
      this.refresh()
    }
  }
}
